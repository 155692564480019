<template>
  <base-section
    id="affiliates"
    space="36"
  >
    <v-container>
      <base-section-heading
        :title="$t('home.title3')"
        mobile-size="text-h5"
      />

      <v-slide-group class="group">
        <template v-for="(item, i) in list">
          <v-slide-item
            :key="i"
            class="align-self-center"
          >
            <base-img
              :src="item.img"
              color="grey"
              contain
              :height="item.height"
              :width="isMobile ? 96 : 128"
            />
          </v-slide-item>

          <v-responsive
            v-if="i < 6"
            :key="`divider-${i}`"
            class="text-center"
            height="75"
            width="10"
          >
            <v-divider vertical />
          </v-responsive>
        </template>
      </v-slide-group>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'SectionAffiliates',
    data() {
      return {
        list: [
          {
            img: require('@/assets/partner/1.png'),
            height: 60,
          },
          {
            img: require('@/assets/partner/2.svg'),
            height: 60,
          },
          {
            img: require('@/assets/partner/6.png'),
            height: 70,
          },
          {
            img: require('@/assets/partner/7.png'),
            height: 60,
          },
          {
            img: require('@/assets/partner/3.png'),
            height: 70,
          },
          {
            img: require('@/assets/partner/4.png'),
            height: 60,
          },
          {
            img: require('@/assets/partner/5.png'),
            height: 45,
          },
        ],
      }
    },
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.width <= 768
      },
    },
  }
</script>
<style scoped>
  .group {
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>
